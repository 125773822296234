import React from "react"
import { propertySold } from "../../../site/urls"
import SearchResultsTemplate from "../../../templates/search-results-template"

const PropertySold = props => {
  const location = props.location
  return (
    <SearchResultsTemplate
      locationName={propertySold}
      location={location}
      pCategoryType={["residential","auction","new_developments"]}
      pType="sales"
      pSubPath="sold"
      pStatus="Sold"
    />
  )
}

export default PropertySold
